import React from "react";

import AppInputGroup from "../../components/inputs/AppInputGroup";
import TitleButton from "../../components/buttons/TittleButton";
import AppButton from "../../components/buttons/AppButton";
import { UserContext } from "../../../context/UserContext";
import Appinput from '../../components/inputs/AppInput';
import Tab from "../../components/tabs/tab";
import RegisterCompany from "../RegisterCompany";
const OwnersAndManagers = ({ handleNext }) => {
 

    return <>
     <RegisterCompany />
       <div className="w-screen h-screen flex justify-center">
        <div className="modal">
            <h2 className="modal-title">Руководитель компании</h2>
            <div className="app_form">
                <Appinput
                    required={true}
                    
                    name={'full_name'}
                    hint="ФИО полностью"
                    
                />
                <Appinput
                    required={true}
                    hint="Дата рождения"
                    
                    name={'date_of_birth'}
                    
                />
                <Appinput
                    required={true}
                    hint="Место рождения"
                    
                    
                    name={'place_of_birth'}
                />
                <Appinput
                    required={true}
                    hint="Гражданство"
                    
                    name={'citizenship'}
                    
                />
                <AppInputGroup
                    required={true}
                 
                    hint_first='Серия паспорта'
                    hint_second='Номер паспорта'
                    
                   
                    name_first={'passport_series'}
                    name_second={'passport_number'}
                />
                <AppInputGroup
                    required={true}
                   
                    hint_second='Кем выдан'
                    hint_first='Дата выдачи'
                    
                    name_second={'issued_by'}
                   
                    name_first={'date_of_issue'}
                />
                <Appinput
                    required={true}
                    hint="Должность"
                    
                    name={'job_title'}
                    
                />
            </div>
            <TitleButton text='Учредители'  />
            
                <>
                    <div className="tabs">
                        <Tab text='Физическое лицо'  />
                        <Tab text='Юридическое лицо'  />
                    </div>
                    <div className="app_form">
                       
                            <>
                                <Appinput
                                   
                                    hint="ФИО полностью"
                                    
                                    
                                    name={'individual_full_name'}
                                />
                                <Appinput
                                    
                                    hint="Дата рождения"
                                    
                                    
                                    name={'individual_date_of_birth'}
                                />
                                <Appinput
                                    required={true}
                                    hint="Место рождения"
                                   
                                    
                                    name={'individual_place_of_birth'}
                                />
                                <Appinput
                                    hint="Гражданство"
                                    required={true}
                                    
                                    
                                    name={'individual_citizenship'}
                                />
                                <AppInputGroup
                                    required={true}
                                   
                                    hint_first='Серия паспорта'
                                    hint_second='Номер паспорта'
                                    
                                    name_first={'individual_passport_series'}
                                    name_second={'individual_passport_number'}
                                />
                                <AppInputGroup
                                    required={true}
                                    
                                    hint_second='Кем выдан'
                                    hint_first='Дата выдачи'
                                    
                                    name_second={'individual_issued_by'}
                                    
                                    name_first={'individual_date_of_issue'}
                                />
                                <Appinput
                                    hint="Должность"
                                    required={true}
                                    
                                    name={'individual_job_title'}
                                />
                                <Appinput
                                    required={true}
                                    hint="Доля в компании"
                                    
                                    name={'individual_share_company'}
                                />
                            </>
                            :
                            <>
                                <Appinput
                                    hint="Название"
                                    required={true}
                                    
                                    name={'founders_name'}
                                    
                                />
                                <Appinput
                                    hint="ИНН"
                                    required={true}
                                    
                                    name={'founders_iin'}
                                    
                                />
                                <Appinput
                                    required={true}
                                    hint="Доля в компании"
                                    
                                    name={'founders_share_company'}
                                />
                            </>
                        
                    </div>
                </>
                <a href="/uploadDocs">
            <AppButton text='Далее'  />
                </a>
        </div>
        </div>
    </>
};

export default OwnersAndManagers;