import React from "react";

import AppButton from "../../components/buttons/AppButton";
import Appinput from '../../components/inputs/AppInput';
import { UserContext } from "../../../context/UserContext";
import RegisterCompany from "../RegisterCompany";

const Addresses = ({ handleNext }) => {
    // const {
    //     state: {
    //         signup: {
    //             legal_city,
    //             legal_index,
    //             legal_house,
    //             legal_street,
    //             legal_office,
    //             actually_city,
    //             actually_index,
    //             actually_house,
    //             actually_street,
    //             actually_office
    //         }
    //     },
    //     dispatch
    // } = React.useContext(UserContext);

    // const handleInput = (type, value) => {
    //     dispatch({ type: 'signup', data: { type, value } });
    // };

    return <>
    <RegisterCompany />
       <div className="w-screen h-screen flex justify-center">
        <div className="modal">
                
            <h2 className="modal-title">Юридический адрес</h2>
            <div className="app_form">
                <Appinput
                    hint="Город"
                    required={true}
                    
                    name={'legal_city'}
                    
                />
                <Appinput
                    hint="Улица"
                    required={true}
                    
                    name={'legal_street'}
                   
                />
                <Appinput
                    hint="Дом"
                    required={true}
                    
                    name={'legal_house'}
                    
                />
                <Appinput
                    hint="Корпус"
                    required={true}
                    name={'legal_block'}
                    
                />
                <Appinput
                    required={true}
                    
                    name={'legal_office'}
                    hint="Офис (квартира)"
                    
                />
                <Appinput
                    hint="Индекс"
                    required={true}
                    
                    name={'legal_index'}
                    
                />
            </div>
            <h2 className="modal-title">Фактический адрес</h2>
            <div className="app_form">
                <Appinput
                    hint="Город"
                    required={true}
                    
                    name={'actually_city'}
                    
                />
                <Appinput
                    hint="Улица"
                    required={true}
                    
                    name={'actually_street'}
                    
                />
                <Appinput
                    hint="Дом"
                    required={true}
                    
                    name={'actually_house'}
                    
                />
                <Appinput
                    hint="Корпус"
                    required={true}
                    name={'actually_block'}
                    
                />
                <Appinput
                    required={true}
                    
                    name={'actually_office'}
                    hint="Офис (квартира)"
                    
                />
                <Appinput
                    hint="Индекс"
                    required={true}
                    
                    name={'actually_index'}
                    
                />
            </div>
            <a href="/bankaccount">
            <AppButton text='Далее'  />
            </a>
        </div>
        </div>

    </>
};

export default Addresses;