import React, {useEffect, useState} from 'react'

function AboutCompany() {

    const [company_data, setCompanyData] = useState(null)

    const getUserData = async () => {

        // let user = localStorage.getItem('user');
        // user = JSON.parse(user);
        // console.log(user, 'user')

        const api = `http://80.78.246.59/practic-app/public/api/about-company`;
        const token = localStorage.getItem('accessToken');

        const res = await fetch(api,{
            method:'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        })
        const data = await res.json()

        setCompanyData(data.data[0]);

        console.log(data.data[0], '/about-company')

    }

    useEffect(() => {

        getUserData();

    }, []);


  return (

   <>
   <p className='text-center md:text-left font-[600] w-[320px] h-[40px] mt-[30px]  text-[34px] md:text-[34px] text-[#333333] md:w-[760px] lg:w-[1024px] 2xl:w-[1170px]  mx-auto '>О компании</p>

       {company_data &&
          <div className='mx-auto  w-[320px] md:w-[760px] bg-white lg:w-[1024px] 2xl:w-[1170px] form  rounded-[8px] mb-[35px] font-["Raleway"] '>
           <div className='w-[100%]  text-center md:text-left   md:flex md:justify-around mt-[30px]    '>
               <div className='mt-[35px]'>
                   <p className='text-[20px] font-[600] text-[#333333] pt-[32px] md:pt-0'>Название (полностью)</p>
                   <input type="text" className=' w-[270px] md:w-[320px] lg:w-[430px] 2xl:w-[530px] input rounded-[8px] mt-[13px]' placeholder={company_data.company_name} disabled />
               </div>
               <div className='mt-[12px] md:mt-[35px]'>
                   <p><span className='text-[20px] font-[600] text-[#333333]'>ИНН</span></p>
                   <input type="text" className=' w-[270px] md:w-[320px] lg:w-[430px] 2xl:w-[530px] input rounded-[8px] mt-[13px] ' placeholder={company_data.iin} disabled />
               </div>
           </div>
           <div>

           </div>
           <div className='w-[100%]  text-center md:text-left   md:flex md:justify-around md:mt-[25px]   '>
               <div className=''>
                   <p className='text-[20px] font-[600] text-[#333333] pt-[32px] md:pt-0'>Вид деятельности</p>
                   <input type="text" className=' w-[270px]  md:w-[320px] lg:w-[430px] 2xl:w-[530px] input rounded-[8px] mt-[13px]' placeholder={company_data.kind_of_activity} disabled />
               </div>
               <div className=''>
                   <p><span className='text-[20px] font-[600] text-[#333333]'>ОГРН / ОРНИП</span></p>
                   <input type="text" className=' w-[270px]  md:w-[320px] lg:w-[430px] 2xl:w-[530px] input rounded-[8px] mt-[13px] ' placeholder={company_data.ogrn} disabled />
               </div>
           </div>
           <div>

           </div>
           <div className='w-[100%]  text-center   md:text-left  md:flex md:justify-around md:pt-[25px]    '>
               <div className=' '>
                   <p className='text-[20px] font-[600] text-[#333333]'>Юридический адрес</p>
                   <input type="text" className=' w-[270px]  md:w-[320px] lg:w-[430px] 2xl:w-[530px] input rounded-[8px] mt-[13px]' placeholder={company_data.legal} disabled />
               </div>
               <div className=' '>
                   <p className='text-[20px] font-[600] text-[#333333]'>Учредители</p>
                   <input type="text" className='w-[270px] md:w-[320px] lg:w-[430px] 2xl:w-[530px] text-[17px] text-[#333333] input rounded-[8px] mt-[13px] ' placeholder={company_data.founders_name} disabled />
               </div>

           </div>
           <div>
               <div className='w-[100%]  text-center md:text-left   md:flex md:justify-around md:mt-[25px]   '>
                   <div className='mb-[25px] md:mb-[50px]'>
                       <p className='text-[20px] font-[600] text-[#333333] pt-[32px] md:pt-0'>Фактический адрес</p>
                       <input type="text" className=' w-[270px]  md:w-[320px] lg:w-[430px] 2xl:w-[530px] input rounded-[8px] mt-[13px]' placeholder={company_data.actually_street} disabled />
                   </div>
                   <div className=' '>
                       <p><span className='text-[20px] font-[600] text-[#333333]'>Банковские реквизиты</span></p>
                       <input type="text" className=' w-[270px]  md:w-[320px] mb-[25px] md:mb-0 lg:w-[430px] 2xl:w-[530px] input rounded-[8px] mt-[13px] ' placeholder={company_data.name_bank} disabled />
                   </div>
               </div>
               <div>

               </div>
           </div>

       </div>

       }
     </>
  )
}

export default AboutCompany
