import React from "react";
import './style.css';

const AppSelect = ({ options = [], required, onChange, name, value, placeholder }) => {
    return <>
        <select required={required} value={value || 'default'} onChange={(e) => onChange?.(name, e.target.value)} className="app_select">
            {options.map((option, idx) => {
                return (
                    <option key={idx.toString()} value={option.value}>{option.name}</option>
                )
            })}
            <option disabled value={'default'}>{placeholder}</option>
        </select>
    </>
};

export default AppSelect;