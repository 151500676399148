import React, { useState } from 'react';
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import swal from 'sweetalert';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import RegisterScreen from './screens/Register/Register'
async function loginUser(credentials) {
  return fetch('http://80.78.246.59/practic-app/public/api/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())

 }

export default function Signin() {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorEmail,setErrorEmail] =useState('');
  const [errorPassword,setErrorPassword] =useState('');


  const handleSubmit = async e => {
    e.preventDefault();
    const response = await loginUser({
      email,
      password
    });
    if ('token' in response) {
      console.log(response)
      swal("You are Logged", {
        buttons: false,
        timer: 2000,
      })
      .then((value) => {
        localStorage.setItem('accessToken', response['token']);
        localStorage.setItem('user', JSON.stringify(response['user']));
        window.location.href = "/";


      }).catch((err)=>console.log(err));
    } else {
      console.log(response)
      swal("Failed", response.error_message, "error");
      window.location.href = "/";
    }
  }



  return (
   <>
 <div className=' flex w-screen h-screen justify-center items-center font-["Raleway"]'>
    <div className='w-[320px] h-[419px]  form rounded-[8px] md:w-[570px]  bg-white font-["Raleway"] '>
    <form  onSubmit={handleSubmit} >
   <div className='flex flex-col  '>
     <p className='text-center text-[40px] font-[600] pt-[38px]'>Вход</p>
     <span className='text-center text-red-600 mt-[17px]'>{errorEmail}</span>

   <input  type="text" placeholder='Эл. почта' onChange={e => setEmail(e.target.value)}  className='mx-auto border-px border-solid border-[#BEBEBE] text-[17px] font-[400]   rounded-[8px] md:w-[421px] md:h-[50px]'  />
   <span className='text-center text-red-600 mt-[20px] '>{errorPassword}</span>

   <input  type="password" placeholder='Пароль' onChange={e => setPassword(e.target.value)}   className='mx-auto rounded-[8px] border-px border-solid border-[#BEBEBE] text-[17px] font-[400]  md:w-[421px] md:h-[50px]' />
   </div>
   <div className='md:w-[421px] flex justify-end mx-auto mt-[7px]'>
     <a href='/recovery' className='font-[600] text-[13px] underline cursor-pointer'>
     Забыли пароль
     </a>
   </div>
   <div className=' mt-[28px] mb-[150px]'>
   <button  className=' md:w-[260px] md:h-[50px] text-[17px] text-white flex justify-center items-center mx-auto bg-[#5354E4] rounded-[13px]'>Войти</button>
<p className='text-center text-[13px] font-[600] pt-[10px] mb-[50px]'>Нет аккаунта ? <a className='text-[#5354E4] cursor-pointer' href='/registerScreen' > Зарегистрироваться </a></p>

   </div>
   </form>
 </div>
 </div>
 </>
  );
}
