import React from "react";

import AppButton from "../../components/buttons/AppButton";
import UploadFile from "../../components/cards/UploadFile";
import { UserContext } from "../../../context/UserContext";
import Tab from "../../components/tabs/tab";
import RegisterCompany from "../RegisterCompany";
const UploadDocuments = () => {
  
    return <>
    <RegisterCompany />
       <div className="w-screen h-screen flex justify-center">
    
        <div className="modal ">
            <h2 className="modal-title">Загрузка документов</h2>
            <div className="tabs">
                <Tab text='Юридическое лицо'  />
                <Tab text='Инд. Прелприниматель'  />
            </div>
            <div className="app_form ">
                
                    <>
                        <UploadFile
                            text='Скан пасспорта'
                            
                            name={'legal_passport_scan'}
                            
                        />
                        <UploadFile
                            text='Устав'
                            name={'legal_charter'}
                           
                        />
                        <UploadFile
                            text='Выписка ЕГРЮЛ'
                            name={'legal_extract'}
                            
                        />
                        <UploadFile
                            name={'legal_license'}
                            
                            text='Лицензия (при наличии)'
                        />
                        <UploadFile
                            
                            name={'legal_executive_agency'}
                           
                            text='Решение об избрании единоличного исполнительного органа'
                        />
                        <UploadFile
                            
                            name={'legal_information_mail'}
                            
                            text='Информационное письмо управления статистики о включении в состав'
                        />
                        <UploadFile
                            name={'legal_egrn'}
                            
                            text='Выписка из ЕГРН либо копия договора аренды или копия свидетельства о ...'
                        />
                        <UploadFile
                            name={'legal_1c'}
                           
                            text='Выписка в формате 1С'
                        />
                    </>
                    :
                    <>
                        <UploadFile
                            text='Скан пасспорта'
                            
                            name={'individual_scan_password'}
                            
                        />
                        <UploadFile
                            text='Лицензия (при наличии)'
                            
                            name={'individual_legal_license'}
                            
                        />
                        <UploadFile
                            
                            name={'individual_information_mail'}
                            
                            text='Информационное письмо управления статистики о включении в состав ...'
                        />
                        <UploadFile
                            
                            name={'individual_tax_regime'}
                            
                            text='Налоговый режим (выбор из списка ОСН или УСН)'
                        />
                    </>
                
            </div>
            <a href="/">
            <AppButton text='Далее'  />
            </a>
        </div>
        </div>
    </>
};

export default UploadDocuments;