import React from "react";
import './style.css';

const AppInputGroup = ({ required, value_first, value_second, hint_first, hint_second, onChange, name_first, name_second }) => {
    return <>
        <div className="app_inputs">
            <input required={required} value={value_first} className='app_input_group' type="text" placeholder={hint_first} onChange={(e) => onChange?.(name_first, e.target.value)} />
            <input required={required} value={value_second} className='app_input_group' type="text" placeholder={hint_second} onChange={(e) => onChange?.(name_second, e.target.value)} />
        </div>
    </>
};

export default AppInputGroup;