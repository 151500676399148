import React from "react";
import './style.css';

const TitleButton = ({ text, onAdd, hide = false }) => {
    return <>
        <div className="title_button">
            <p>{text}</p>
            {!hide && <button onClick={onAdd}>Добавить</button>}
        </div>
    </>
};

export default TitleButton;