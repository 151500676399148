import React from "react";

import AppButton from '../components/buttons/AppButton';
import AppInput from "../components/inputs/AppInput";
// import { UserContext } from "../../context/UserContext";

const NewPassScreen = () => {
   

    return <>
        <div className="flex w-screen h-screen justify-center items-center ">
        <div className="modal">
            <h1 className="title">Восстановление
                аккаунта</h1>
            <h3 className="subtitle">Придумайте сложный пароль,содержащий
                строчные и прописные буквы,а так же цифры
                и символы</h3>
            <div className="app_form">
                <AppInput
                    
                    name={'password'}
                    type={'password'}
                    hint="Новый пароль"
                    
                />
                <AppInput
                    type={'password'}
                    hint="Повтор пароля"
                    
                    name={'password_confirmation'}
                />
            </div>
            <a href="/">
            <AppButton
                text='Войти'
                
            />
            </a>
        </div>
        </div>
    </>
};

export default NewPassScreen;