import React from "react";

import AppButton from '../components/buttons/AppButton';
import AppInput from "../components/inputs/AppInput";
import { UserContext } from "../../context/UserContext";

const RecoveryScreen = () => {
  

    return <>
    <div className="flex w-screen h-screen justify-center items-center">
        <div className="modal">
            <h1 className="title">Восстановление
                аккаунта</h1>
            <h3 className="subtitle">Мы отправим 4-х значный код на вашу эл.почту для подтверждения личности</h3>
            <div className="app_form">
                <AppInput
                    
                    name={'email'}
                    hint="Эл. почта"
                    required={true}
                    
                />
            </div>
            <a href="/newpass">
            <AppButton text='Отправить код'  />
            </a>
        </div>
        </div>
    </>
};

export default RecoveryScreen;