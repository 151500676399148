import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useState,useEffect} from 'react'
import Navbar from './Navbar'
import Income from "./components/Income";
import Transactions from "./components/Transactions";
import FormLink from "./components/FormLink";
import FormAccount from "./components/FormAccount";
import Signin from './Signin'
import BarChart from "./components/BarChart";
import {UserData} from './Data.js'
import {SecondData} from './SecondData'
import ConnectPay from "./components/ConnectPay";
import BaseLayout from "./components/childComponents/MainIncome";
import SearchTransactions from "./components/childComponents/SearchTransactions";

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import {Link} from "react-router-dom";
import {Line} from 'react-chartjs-2'
import {Chart as ChartJS} from 'chart.js/auto'
import Footer from './components/Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

}));

export default function Profile() {

  const classes = useStyles();

  const user = JSON.parse(localStorage.getItem('user'));
  const [mounth, setMounth] = useState('01')
  const [transactions, setTransactions] = useState([])
  const [main_income_figures, setMainincomeFigures] = useState({})
  const [error_transactions_count, setErrorTransactionsCount] = useState(null)

  const [chart_options, setChartOptions] = useState({
      responsive: true,
      plugins: {
          legend: {
              position: 'top',
          },
          title: {
              display: true,
              text: 'Chart.js Line Chart',
          },
      },
  })

const [chartData, setChartData] = useState(null)





  let transactions_simulate_data = [
      {
          "id": 2,
          "mount": "10",
          "when": "2022-10-25",
          "how": "Картой",
          "how_many": 200,
          "commission": "3,50",
          "commission_amount": "300",
          "operation_status": "Возврат",
          "user_id": 5,
          "transaction_status": "false",
          "created_at": "2022-08-03T14:19:39.000000Z",
          "updated_at": "2022-08-03T14:19:39.000000Z"
      },
      {
          "id": 3,
          "mount": "10",
          "when": "2022-10-25",
          "how": "СПБ",
          "how_many": 250,
          "commission": "4,20",
          "commission_amount": "450",
          "operation_status": "Возврат",
          "user_id": 5,
          "transaction_status": "false",
          "created_at": "2022-10-24T13:06:48.000000Z",
          "updated_at": "2022-10-24T13:06:48.000000Z"
      },
      {
          "id": 4,
          "mount": "10",
          "when": "2022-10-25",
          "how": "Картой",
          "how_many": 100,
          "commission": "9,50",
          "commission_amount": "500",
          "operation_status": "Возврат",
          "user_id": 5,
          "transaction_status": "false",
          "created_at": "2022-10-24T13:07:18.000000Z",
          "updated_at": "2022-10-24T13:07:18.000000Z"
      },
      {
          "id": 5,
          "mount": "10",
          "when": "2022-10-25",
          "how": "СПБ",
          "how_many": 500,
          "commission": "3,40",
          "commission_amount": "870",
          "operation_status": "Возврат",
          "user_id": 5,
          "transaction_status": "true",
          "created_at": "2022-10-24T13:19:48.000000Z",
          "updated_at": "2022-10-24T13:19:48.000000Z"
      },
      {
          "id": 6,
          "mount": "10",
          "when": "2022-10-26",
          "how": "СПБ",
          "how_many": 100,
          "commission": "7,80",
          "commission_amount": "120",
          "operation_status": "Возврат",
          "user_id": 5,
          "transaction_status": "false",
          "created_at": "2022-10-25T09:35:27.000000Z",
          "updated_at": "2022-10-25T09:35:27.000000Z"
      },
      {
          "id": 7,
          "mount": "10",
          "when": "2022-10-26",
          "how": "Картой",
          "how_many": 100,
          "commission": "5,90",
          "commission_amount": "600",
          "operation_status": "Возврат",
          "user_id": 5,
          "transaction_status": "false",
          "created_at": "2022-10-25T09:35:32.000000Z",
          "updated_at": "2022-10-25T09:35:32.000000Z"
      },
      {
          "id": 8,
          "mount": "10",
          "when": "2022-10-26",
          "how": "СПБ",
          "how_many": 100,
          "commission": "7,80",
          "commission_amount": "120",
          "operation_status": "Возврат",
          "user_id": 5,
          "transaction_status": "false",
          "created_at": "2022-10-25T09:35:27.000000Z",
          "updated_at": "2022-10-25T09:35:27.000000Z"
      },
      {
          "id": 9,
          "mount": "10",
          "when": "2022-10-27",
          "how": "Картой",
          "how_many": 100,
          "commission": "5,90",
          "commission_amount": "600",
          "operation_status": "Возврат",
          "user_id": 5,
          "transaction_status": "false",
          "created_at": "2022-10-25T09:35:32.000000Z",
          "updated_at": "2022-10-25T09:35:32.000000Z"
      },
      {
          "id": 10,
          "mount": "10",
          "when": "2022-10-27",
          "how": "Картой",
          "how_many": 100,
          "commission": "5,90",
          "commission_amount": "600",
          "operation_status": "Возврат",
          "user_id": 5,
          "transaction_status": "false",
          "created_at": "2022-10-25T09:35:32.000000Z",
          "updated_at": "2022-10-25T09:35:32.000000Z"
      }
  ]

    //CHART START

    // const data = {
    //     labels: chart_labels,
    //     datasets: [
    //         {
    //             label: 'Картой',
    //             data: {  January: 50, February: 60 },
    //             borderColor: 'rgb(255, 99, 132)',
    //             backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //         },
    //         {
    //             label: 'СПБ',
    //             data: { January: 30, February: 40 },
    //             borderColor: 'rgb(53, 162, 235)',
    //             backgroundColor: 'rgba(53, 162, 235, 0.5)',
    //         },
    //     ],
    // };


    //CHART END





    const getAllPageData = async (mounth_new = mounth) => {

        let user_id = user.id;

        console.log(user_id, 'user');
        console.log(mounth_new, 'mounth');

        const api = `http://80.78.246.59/practic-app/public/api/search-transactions?user_id=${user_id}&mount=${mounth_new}`;
        const token = localStorage.getItem('accessToken');

        const res = await fetch(api,{
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + token
            }
        })
        const data = await res.json()
        console.log(data['transaction count data'])

        setTransactions(data.data);
        setMainincomeFigures(data['transaction count data']);

        let error_transactions_count = 0;
        data.data.forEach((item) => {
            if(item.transaction_status == 'false') {
                error_transactions_count = error_transactions_count +1;
            }
        })

        console.log(error_transactions_count, 'error_transactions_count')

        setErrorTransactionsCount(error_transactions_count)

        // Set Chart Data Start

        let card_trans = [];
        let spb_trans = [];
        data.data.forEach((item, index) => {

            if (item.how == 'Картой') {
                card_trans.push(item)
            } else if(item.how == 'СПБ') {
                spb_trans.push(item)
            }
        })


        let sort_by_date_card_trans = {};
        card_trans.forEach((item, index) => {
            if(!sort_by_date_card_trans[item.when]) {
                sort_by_date_card_trans[item.when]= item.how_many
            } else {
                let num = sort_by_date_card_trans[item.when];
                sort_by_date_card_trans[item.when] = num+ item.how_many;

            }
            // sort_by_date_card_trans[item.when].push(item.how_many)
        })


        let sort_by_date_spb_trans = {};
        spb_trans.forEach((item, index) => {
            if(!sort_by_date_spb_trans[item.when]) {
                sort_by_date_spb_trans[item.when] = item.how_many
            } else {
                let num = sort_by_date_spb_trans[item.when];

                sort_by_date_spb_trans[item.when] = num+ item.how_many

            }
        })

        console.log(typeof sort_by_date_card_trans , 'sort_by_date_card_trans')
        console.log(typeof sort_by_date_spb_trans , 'sort_by_date_spb_trans')


        setChartData({
            // labels: ['2022-10-25', '2022-10-26'],
            datasets: [
                {
                    label: 'Картой',
                    data: sort_by_date_card_trans,
                    // data: {'2022-10-25': 55},
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: '#5354E4',
                },
                {
                    label: 'СПБ',
                    data: sort_by_date_spb_trans,
                    // data: {'2022-10-25': 555},
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: '#B338FF',
                },
            ],
        })
        // Set Chart Data End


    };


    const inicialiseDataTable = async () => {

        console.log(transactions, 'FROM TABLE')
        if (!$.fn.DataTable.isDataTable("#table")) {

            $(document).ready(function () {

                setTimeout(function () {

                    $("#table").dataTable().fnDestroy();
                    $("#table").DataTable({
                        pagingType: "full_numbers",
                        pageLength: 20,
                        processing: true,
                        dom: "Bfrtip",
                        select: {
                            style: "single",
                        },

                        buttons: [

                        ],

                        // fnRowCallback: function (
                        //     nRow,
                        //     aData,
                        //     iDisplayIndex,
                        //     iDisplayIndexFull
                        // ) {
                        //     var index = iDisplayIndexFull + 1;
                        //     // $("td:first", nRow).html(index);
                        //     return nRow;
                        // },

                        lengthMenu: [
                            [10, 20, 30, 50, -1],
                            [10, 20, 30, 50, "All"],
                        ],
                        columnDefs: [
                            {
                                targets: 0,
                                render: function (data, type, row, meta) {
                                    return type === "export" ? meta.row + 1 : data;
                                },
                            },
                        ],
                    });
                }, 1000);
            });
        }
    }

    useEffect(() => {

      getAllPageData();
      inicialiseDataTable();

  }, []);


    const openSingleTransaction = (event) => {
        console.log(JSON.parse( event.target.getAttribute("data-item")), 'openSingleTransaction')
    }
    const users = localStorage.getItem('user')
    const token = localStorage.getItem('accesstoken')
    // if(!users){
    //     return <Signin />
    // }

    function showTable () {
        try {
            return transactions.map((item, index) => {
                return (
                    <tr  key={index} data-item={item} className='border-2 border-solid text-[10px] md:text-[18px]'  >
                        <td data-item={JSON.stringify(item)} onClick={openSingleTransaction}  className="border-[1px] text-center border-solid">


                            <Link to={`/TransactionDetails/${item.id}/${item.when}/${item.how}/${item.how_many}/${item.commission}/${item.commission_amount}/${item.operation_status}`}>
                                {item.when}
                            </Link>
                        </td>
                        <td data-item={JSON.stringify(item)} onClick={openSingleTransaction} className="border-[1px] border-b-2 text-center border-solid">
                             <Link to={`/TransactionDetails/${item.id}/${item.when}/${item.how}/${item.how_many}/${item.commission}/${item.commission_amount}/${item.operation_status}`} className=''>
                                 {item.how}
                            </Link>
                        </td>
                        <td data-item={JSON.stringify(item)} onClick={openSingleTransaction} className="border-[1px] text-center border-solid">
                             <Link to={`/TransactionDetails/${item.id}/${item.when}/${item.how}/${item.how_many}/${item.commission}/${item.commission_amount}/${item.operation_status}`}>
                                 {item.how_many} <span className='text-[#5354E4] '> ₽</span>
                            </Link>
                        </td>
                        <td data-item={JSON.stringify(item)} onClick={openSingleTransaction} className="border-[1px] text-center  border-solid">
                             <Link to={`/TransactionDetails/${item.id}/${item.when}/${item.how}/${item.how_many}/${item.commission}/${item.commission_amount}/${item.operation_status}`}>
                                 {item.commission}<span className='text-[#5354E4] '> %</span>
                            </Link>
                        </td>
                        <td data-item={JSON.stringify(item)} onClick={openSingleTransaction} className="border-[1px] text-center border-solid">
                             <Link to={`/TransactionDetails/${item.id}/${item.when}/${item.how}/${item.how_many}/${item.commission}/${item.commission_amount}/${item.operation_status}`}>
                                 {item.commission_amount} <span className='text-[#5354E4] '> ₽</span>
                            </Link>
                        </td>
                        <td data-item={JSON.stringify(item)} onClick={openSingleTransaction} className="border-[1px] text-center  border-solid">
                             <Link to={`/TransactionDetails/${item.id}/${item.when}/${item.how}/${item.how_many}/${item.commission}/${item.commission_amount}/${item.operation_status}`}>
                                 {item.operation_status}
                            </Link>
                        </td>
                    </tr>
                );
            });

        } catch (e) {
            // alert(e.message);
        }
    };

    const changeMonthFunc = async (event) => {
        await setMounth(event.target.value)
        $("#table").dataTable().fnDestroy();
        await getAllPageData(event.target.value)
        await  inicialiseDataTable()
    }

  return (
    <>
    <div className=''>


        {/*<Income />*/}

        {/*Income START*/}
        <div className='mx-auto'>
            {/*<MainIncomeChild />*/}
            <div className='mx-auto text-center md:w-[760px] lg:w-[1024px] 2xl:w-[1170px]   md:flex md:justify-between mt-[30px]    '>
                <div className='h-[40px] font-[600] text-[34px] text-[#333333]  '>
                    Главные цифры доходов
                </div>
                <div className='mt-[10px]'>

                        <select onChange={changeMonthFunc} name="" id="" value={mounth} className='w-[270px] h-[45px] bg-[#FFFFFF] rounded-[8px] text-[22px] font-[500] border-none leading-[22.5px]'>

                            <option value="01">Январь</option>
                            <option value="02">Февраль</option>
                            <option value="03">Март</option>
                            <option value="04">Апрель</option>
                            <option value="05">Май</option>
                            <option value="06">Июнь</option>
                            <option value="07">Июль</option>
                            <option value="08">Август</option>
                            <option value="09">Сентябрь</option>
                            <option value="10">Октябрь</option>
                            <option value="11">Ноябрь</option>
                            <option value="12">Декабрь</option>

                        </select>

                </div>
            </div>

            <div className='w-[320px] md:w-[796px] lg:w-[1053px] 2xl:w-[1270px]     md:flex md:justify-self-start mx-auto '>
                <BaseLayout>
                    <BaseLayout.Header>
                        <h1>Кол-во транзакций</h1>
                    </BaseLayout.Header>

                    <BaseLayout.Body>

                        {main_income_figures &&

                            <p>{main_income_figures.count}</p>
                        }

                    </BaseLayout.Body>

                </BaseLayout>
                <BaseLayout>


                    <BaseLayout.Header>
                        <h1>Поступления</h1>
                    </BaseLayout.Header>

                    <BaseLayout.Body>
                        {main_income_figures &&

                             <p>{main_income_figures.success_transaction_sum} <span className='text-[#5354E4] '> ₽</span> </p>

                        }

                    </BaseLayout.Body>

                    <BaseLayout.Footer>
                        {main_income_figures &&
                            <p><span className='text-[#7D7D7D] text-[16px] font-[500]'>{main_income_figures.mistakes_transactions_count} ₽</span> не принято</p>
                        }
                    </BaseLayout.Footer>
                </BaseLayout>



                <BaseLayout>
                    <BaseLayout.Header>
                        <h1>Успешность платежей</h1>
                    </BaseLayout.Header>

                    <BaseLayout.Body>
                        {main_income_figures &&
                          <p> {main_income_figures['success transaction percent']} <span className='text-[#5354E4] '> %</span></p>
                        }
                    </BaseLayout.Body>

                    <BaseLayout.Footer>


                        {error_transactions_count &&
                          <p><span className='text-[#7D7D7D] text-[16px] font-[500]'>Количество ошибок - </span>{error_transactions_count}</p>
                        }
                    </BaseLayout.Footer>
                </BaseLayout>
            </div>
        </div>
        {/*Income END*/}

        {/*<Transactions transactions={transactions} />*/}

        {/* TRansaction START*/}

             <div className='font-["Raleway"] rounded-[8px]'>

             <SearchTransactions />
            {/*<Table transactions={transactions} />*/}

            <div className=' mx-auto  w-[270px]  md:w-[760px] lg:w-[1024px] 2xl:w-[1170px]    '>
                <div className="table-responsive p-0    w-[270px] md:w-[760px] lg:w-[1024px] 2xl:w-[1170px]">
                    <table id="table" className="table   bg-white mb-0 md:h-[410px] w-[270px] md:w-[760px] lg:w-[1024px] 2xl:w-[1170px] form  rounded-[8px]   ">
                        <thead>
                        <tr>
                            <th className="border-[2px] border-solid md:w-[205px] md:h-[50px]">Когда?</th>
                            <th className="border-[2px] border-solid md:w-[205px] md:h-[50px]">Как?</th>
                            <th className="border-[2px] border-solid md:w-[205px] md:h-[50px]">Сколько?</th>
                            <th className="border-[2px] border-solid md:w-[205px] md:h-[50px]">%
                                Комиссии
                            </th>
                            <th className="border-[1px] border-solid md:w-[205px] md:h-[50px]">Сумма
                                комиссии
                            </th>
                            <th className="border-[1px] border-solid md:w-[205px] md:h-[50px]">Статус
                                операции
                            </th>
                        </tr>
                        </thead>

                        <tbody>
                        {showTable()}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Table End */}

        </div>

        {/* Transaction END*/}

        <FormLink  />
        <FormAccount />



        {/*Chart data start*/}
            <p className='font-[600] w-[320px] text-[34px] text-[#333333] md:w-[760px]  text-center md:text-left lg:w-[1024px] 2xl:w-[1170px] mx-auto '>Статистика по способам оплаты</p>

            <div className="some w-auto  md:w-[760px] md:text-center bg-white lg:w-[1024px] 2xl:w-[1170px] form mx-auto flex mt-[30px] mb-[35px] rounded-[8px]">

                {chartData &&

                    <Line  options={chart_options} data={chartData} />

                }
            </div>

        {/*Chart data end*/}

        {/*<BarChart chartData ={{}} />*/}

        <ConnectPay />
    </div>
    <Footer />
    </>
  );
}
