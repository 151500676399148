import React from 'react'
import Footer from '../Footer'
import Navbar from '../../Navbar'
import Connection from './Connection'
import Signin from '../../Signin'
import AboutCompany from './AboutCompany'
import Tariffs from './Tariffs'
function Profile() {
  const users = localStorage.getItem('user')
  if(!users){
      return <Signin />
  }

  return (
    <div>
   
     <AboutCompany />
     <Connection />
     <Tariffs />
     <Footer />
     
     </div>
  )
}

export default Profile