import React from "react";

import AppButton from "../../components/buttons/AppButton";
import AppSelect from "../../components/inputs/AppSelect";
import Appinput from '../../components/inputs/AppInput';
import { UserContext } from "../../../context/UserContext";
import RegisterCompany from "../RegisterCompany";

const GeneralInformation = ({ handleNext }) => {
    // const { state: { signup: { company_name, iin, ogrn, tax_regime, company_profile } }, dispatch } = React.useContext(UserContext);

    // const handleInput = (type, value) => {
    //     dispatch({ type: 'signup', data: { type, value } });
    // };

    return <>
        <RegisterCompany />
       <div className="w-screen h-screen flex justify-center">
       
        <div className="modal ">
            <div className="app_form">
                <Appinput
                    required={true}
                    
                    name={'company_name'}
                    
                    hint="Название компании"
                />
                <Appinput
                    hint="ИНН"
                    
                    name={'iin'}
                    required={true}
                    
                />
                <Appinput
                    
                    name={'ogrn'}
                    required={true}
                    
                    hint="ОГРН (СНИЛС для самозанятых)"
                />
                <AppSelect
                    options={[]}
                    required={true}
                    name={'tax_regime'}
                    
                   
                    placeholder='Профиль компании'
                />
                <AppSelect
                    options={[]}
                    required={true}
                    
                    name={'tax_regime'}
                    
                    placeholder='Налоговый режим'
                />
            </div>
            <a href="/addresses">
            <AppButton text='Далее'  />
            </a>
        </div>
        </div>
    </>
};

export default GeneralInformation;