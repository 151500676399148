import React from "react";

import GeneralInformation from "./steps/GeneralInformation";
import OwnersAndManagers from "./steps/OwnersAndManagers";
import UploadDocuments from "./steps/UploadDocuments";
import BankAccount from "./steps/BankAccount";
import Pill from "../components/cards/pill";
import Addresses from "./steps/Addresses";
import './RegisterCompany.css';

const RegisterCompany = () => {
  
    return <>
        <h1 className="title">Регистрация Компании</h1>
        <div className="pills">
            <Pill text='Общие сведения'  />
            <Pill text='Адреса'  />
            <Pill text='Банковский счёт'  />
            <Pill text='Собственники и менеджеры'  />
            <Pill text='Загрузка документов'  />
        </div>
       
    </>
};

export default RegisterCompany;