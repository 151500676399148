import React from "react";

import AppButton from "../../components/buttons/AppButton";
import Appinput from '../../components/inputs/AppInput';
import { UserContext } from "../../../context/UserContext";
import RegisterCompany from "../RegisterCompany";

const BankAccount = ({ handleNext }) => {
   
    return <>
         <RegisterCompany />
         <div className="w-screen h-screen flex justify-center">
        <div className="modal">
            
            <h2 className="modal-title">Банковский счёт</h2>
            <div className="app_form">
                <Appinput
                    hint="КПП"
                    
                    name={'kpp'}
                    required={true}
                    
                />
                <Appinput
                    required={true}
                    
                    hint="Кореспондентский счёт"
                    
                    name={'correspondent_account'}
                />
                <Appinput
                    hint="БИК"
                    
                    name={'bik'}
                    required={true}
                    
                />
                <Appinput
                    required={true}
                    
                    name={'name_bank'}
                    
                    hint="Наименование банка"
                />
                <Appinput
                    required={true}
                    hint="Номер счёта"
                   
                    name={'account_number'}
                    
                />
            </div>
            <a href="/ownersAndManagers">
            <AppButton text='Далее'  />
            </a>   
        </div>
        </div>

    </>
};

export default BankAccount;