import React from "react";
import './style.css';

const UploadFile = ({ text, onChange, name, isActive }) => {
    return <>
        <div className={"upload_file"}>
            <p className="upload_file_text">{text}</p>
            <div className={`upload_file_input ${isActive && 'active'}`}>
                <input type="file" id="upload" hidden onChange={(e) => onChange?.(name, e.target.files[0])} />
                <label htmlFor="upload">Загрузить</label>
            </div>
        </div>
    </>
};

export default UploadFile;