import React from "react";
import './style.css';

const LogReg = ({ text, btntext, onClick }) => {
    return <>
        <div className="logreg">
            <p>{text}</p>
            <button onClick={onClick}>{btntext}</button>
        </div>
    </>
};

export default LogReg;