import APIRequest from './APIRequest';

class APICalls {

    static request = new APIRequest();

    static set authToken(authToken) {
        this.request.authToken = authToken;
    };

    static login = (email, password) => {
        return this.request.post('/api/login', { email, password });
    };

    static codeRepeat = (verified_code) => {
        return this.request.post('/coderepeat', { verified_code });
    };

    static register = (credentials) => {
        return this.request.post('/api/auth', credentials);
    };

    // FORGOT password calls

    static forgotPassword = (email) => {
        return this.request.post('/api/code-sending', { email });
    };

    static verifyCode = (random_int) => {
        return this.request.post('/api/restore-password', { random_int });
    };

    static updatePassword = (user_id, password) => {
        return this.request.post('/api/update-password', { user_id, password });
    };
};

export default APICalls;