import React from "react";
import { useNavigate } from 'react-router-dom';

import AppButton from '../components/buttons/AppButton';
import { UserContext } from "../../context/UserContext";
import Appinput from '../components/inputs/AppInput';
import LogReg from "../components/links/LogReg";


const RegisterScreen = () => {
 

    return <>
    <div className="flex w-screen h-screen justify-center items-center">
        <div className="modal ">
            <h1 className="title">Регистрация</h1>
            <div className="app_form">
                <Appinput
                    hint="Имя"
                    name={'name'}
                    required={true}
                    
                />
                <Appinput
                    name={'number'}
                    required={true}
                    hint="Тел. номер"
                   
                    type="number"
                />
                <Appinput
                    name={'email'}
                    type={'email'}
                    required={true}
                    hint="Эл. почта"
                    
                />
                <Appinput
                    hint="Пароль"
                    required={true}
                    type={'password'}
                    name={'password'}
                  
                />
                <Appinput
                    required={true}
                    type={'password'}
                    hint="Повтор пароля"
                   
                    name={'password_confirmation'}
                  
                />
            </div>
           <a href="/generalInform"> <AppButton text='Зарегистрироваться'  /> </a>
           <a href="/"> <LogReg text='Уже зарегистрировались?' btntext='Войти'  /></a>
        </div>
        </div>
    </>
};

export default RegisterScreen;