import React from 'react'

function Footer() {
  return (
      <footer className='w-full border-t-2 border-solid border-gray-300 font-["Raleway"] font-[400] text-[#333333]'>
       <div className='mx-auto text-center  pt-[54px] mb-[54px] md:w-[760px] lg:w-[1024px] 2xl:w-[1170px] md:flex md:justify-start'>
        <div>
         <img src={require('../assets/Logo.png')} className='  w-[122px] mx-auto md:mx-0' />
         <p className='pt-[54px] '>Denum.Pay © 2022. Все права защищены.</p>
        </div>
        <div className='md:pl-[55px] lg:pl-[155px] xl:pl-[255px]'>
         <p className='md:text-left'>Прием платежей</p>
         <p className='pt-[20px] '>Отправка платежей</p>
        </div>
        <div className='md:pl-[59px] lg:pl-[79px] xl:pl-[109px] xl:pl-[209px] '>
         <p  >Учет и Аналитика</p>
         <p className='pt-[20px] md:text-left'>Профиль</p>
        </div>

       </div>
         
      </footer>
  )
}

export default Footer