import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Signin from './Signin';
import Profile from './Profile';
import RegisterScreen from './screens/Register/Register';
import TransactionDetails from '../src/components/TransactionDetails/TransactionDetails'
import ProfileDetails from './components/SecondPage/Profile'
import Navbar from './Navbar'
import RegisterCompany from './screens/RegisterCompany/RegisterCompany';
import NewPassScreen from './screens/NewPass/NewPass';
import Addresses from './screens/RegisterCompany/steps/Addresses';
import GeneralInformation from './screens/RegisterCompany/steps/GeneralInformation';
import BankAccount from './screens/RegisterCompany/steps/BankAccount';
import OwnersAndManagers from './screens/RegisterCompany/steps/OwnersAndManagers';
import UploadDocuments from './screens/RegisterCompany/steps/UploadDocuments';
import RecoveryScreen from './screens/Recovery/Recovery';
function App() {
  const token = localStorage.getItem('accessToken');




  return (
    <div className="wrapper font-['Raleway']">
      <BrowserRouter>
      {token ? <Navbar /> : ''}

        {token ?

            <Routes>
              {/*<Route path="/" element={<Signin />} />*/}
              <Route path="/" element={<Profile />} />
              <Route path="/details" element={<Profile />} />
              <Route path="/TransactionDetails/:id/:when/:how/:how_many/:commission/:commission_amount/:operation_status" element={<TransactionDetails />} />
              <Route path="/profile" element={<ProfileDetails />} />

              {/*<Route path="/registerScreen" element={<RegisterScreen />} caseSensitive='true' />*/}
              {/*<Route path='/registerCompany' element={<RegisterCompany/>} caseSensitive='true' />*/}
              {/*<Route path='/addresses' element={<Addresses />} caseSensitive='true' />*/}
              {/*<Route path='/generalInform' element={<GeneralInformation />} caseSensitive='true' />*/}
              {/*<Route path='/bankaccount' element={<BankAccount />} caseSensitive='true' />*/}
              {/*<Route path='/ownersAndManagers' element={<OwnersAndManagers />} caseSensitive='true' />*/}
              {/*<Route path='/uploadDocs' element={<UploadDocuments />} caseSensitive='true' />*/}
              {/*<Route path='/recovery' element={<RecoveryScreen />} caseSensitive='true' />*/}
              {/*<Route path='/newpass' element={<NewPassScreen />} caseSensitive='true' />*/}



            </Routes>

            :

            <Routes>
              <Route path="/" element={<Signin />} />

              {/*<Route path="/details" element={<Profile />} />*/}
              {/*<Route path="/TransactionDetails/:id/:when/:how/:how_many/:commission/:commission_amount/:operation_status" element={<TransactionDetails />} />*/}
              {/*<Route path="/profile" element={<ProfileDetails />} />*/}

              <Route path="/registerScreen" element={<RegisterScreen />} caseSensitive='true' />
              <Route path='/registerCompany' element={<RegisterCompany/>} caseSensitive='true' />
              <Route path='/addresses' element={<Addresses />} caseSensitive='true' />
              <Route path='/generalInform' element={<GeneralInformation />} caseSensitive='true' />
              <Route path='/bankaccount' element={<BankAccount />} caseSensitive='true' />
              <Route path='/ownersAndManagers' element={<OwnersAndManagers />} caseSensitive='true' />
              <Route path='/uploadDocs' element={<UploadDocuments />} caseSensitive='true' />
              <Route path='/recovery' element={<RecoveryScreen />} caseSensitive='true' />
              <Route path='/newpass' element={<NewPassScreen />} caseSensitive='true' />



            </Routes>


        }

      </BrowserRouter>
    </div>
  );
}

export default App;
